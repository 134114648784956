import React from 'react';

import { handleError } from '../../services/error.service';
import { formatDate } from '../../services/fmt.service';
import { showModal } from '../../services/modal.service';
import { FormulationQuestion, FormulationUnit, getQuestions } from '../../services/sdk.service';
import { Button } from '../Button';
import { Column, List } from '../List';
import { ModalHeader } from '../ModalHeader';
import { MQuestions } from './MQuestions';

interface P {
  formulationText: string;
  units: FormulationUnit[];
  close: (value?: string) => void;
}

export class MUnits extends React.Component<P> {
  render() {
    return (
      <div className="MUnits modal-dialog modal-lg modal-dialog-scrollable">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  async onItemSelect(unit: FormulationUnit) {
    const { formulationText, units } = this.props;
    let questions: FormulationQuestion[] = [];
    try {
      questions = await getQuestions({ unitId: unit.id, formulationText });
    } catch (e) {
      handleError(e);
      return;
    }
    await showModal(MQuestions, { unit, questions, units });
  }

  // render helpers

  renderHeader() {
    const { close } = this.props;
    return <ModalHeader title="Опросы" close={close} />;
  }

  renderBody() {
    const { units } = this.props;
    return (
      <div className="modal-body">
        <List items={units} columns={this.getColumns()} onItemSelect={(x) => this.onItemSelect(x)} />
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Закрыть" onClick={() => close()} />
      </div>
    );
  }

  // other helpers

  getColumns(): Column<FormulationUnit>[] {
    return [
      {
        name: 'Дата',
        value: (item) => formatDate(item.createdDate),
        headClassName: 'w-120px',
      },
      {
        name: 'Код',
        value: (item) => item.code,
        headClassName: 'w-160px',
      },
      {
        name: 'Название',
        value: (item) => item.title,
      },
    ];
  }
}
