/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';

interface P {
  className?: string;
  text?: string;
  title?: string;
  onClick?: () => void;
}

export class VoidLink extends React.Component<P> {
  render() {
    const { className, text, title } = this.props;
    return (
      <a href="#" className={className} title={title} onClick={(e) => this.onClick(e)}>
        {text ? text : this.props.children}
      </a>
    );
  }

  // event handlers

  onClick(e: React.MouseEvent) {
    const { onClick } = this.props;
    e.preventDefault();
    if (onClick) {
      onClick();
    }
  }
}
