import React from 'react';
import { cn } from '../lib/utils.lib';

export interface Option {
  value: string;
  title: string;
  disabled?: boolean;
}

interface P {
  options: Option[];
  value: string;
  id?: string;
  className?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

export class Select extends React.Component<P> {
  render() {
    const { value, options, id, className, disabled } = this.props;
    return (
      <select
        id={id}
        className={cn(className, 'form-control')}
        value={value}
        disabled={disabled}
        onChange={(e) => this.onChange(e)}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value} disabled={option.disabled}>
            {option.title}
          </option>
        ))}
      </select>
    );
  }

  // event handlers

  onChange(e: any) {
    const { onChange } = this.props;
    const value = e.target.value;
    if (onChange) {
      onChange(value);
    }
  }
}
