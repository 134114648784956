export function areEqual(x: any, y: any) {
  return JSON.stringify(x) === JSON.stringify(y);
}

export function cn(...parts: any[]) {
  return parts.filter((p) => p).join(' ');
}

export function esc(value: string) {
  let result = value;
  result = replace(result, '&', '&amp;');
  result = replace(result, '<', '&lt;');
  result = replace(result, '>', '&gt;');
  result = replace(result, '"', '&quot;');
  result = replace(result, "'", '&#39;');
  result = replace(result, '`', '&#96;');
  return result;
}

export function linkify(text: string) {
  return text.replace(/(https?:\/\/\S+)/g, '<a href="$1" target="_blank">$1</a>');
}

export function pluralize(value: number, w1: string, w2: string, w5: string, appendNumber = true): string {
  const v1 = value % 10; // 123 % 10 = 3
  const v2 = value % 100; // 123 % 100 = 23
  let w = w2;
  if (v1 >= 5 || v1 === 0 || (v2 >= 11 && v2 <= 19)) {
    w = w5;
  } else if (v1 === 1) {
    w = w1;
  }
  return appendNumber ? `${value} ${w}` : w;
}

export function replace(value: string, search: string, replacement: string) {
  return value.split(search).join(replacement);
}

export function sleep(ms = 0) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function sortBy<T, V>(array: T[], by: (item: T) => V): T[] {
  const sorted = [...array];
  sorted.sort((a, b) => {
    const av = by(a);
    const bv = by(b);
    if (av > bv) return 1;
    if (av < bv) return -1;
    return 0;
  });
  return sorted;
}
