import { call } from './api.service';
import { createReader, Reader } from './reader.service';

export interface Formulation {
  text: string;
  usageCount: number;
  lastUnitDate: number;
}

export interface FormulationUnit {
  id: number;
  code: string;
  title: string;
  createdDate: number;
}

export interface FormulationQuestion {
  idPart: number;
  type: number;
  text: string;
  par1: number;
  par2: number;
  par3: number;
  alias: string;
  alts: string[];
}

export enum FormulationSort {
  byUsageCount = 'byUsageCount',
  byLastUnitDate = 'byLastUnitDate',
}

export interface FormulationQuery {
  search: string;
  sort: FormulationSort;
}

export interface FormulationUnitQuery {
  formulationText: string;
}

export interface FormulationQuestionQuery {
  unitId: number;
  formulationText: string;
}

export async function search(query: FormulationQuery): Promise<Formulation[]> {
  const result = await call('Formulating', 'Search', [query.search, query.sort]);
  const reader = createReader(result);
  const formulations: Formulation[] = [];
  while (reader.canRead()) {
    formulations.push({
      text: reader.readString(),
      usageCount: reader.readNumber(),
      lastUnitDate: reader.readDate(),
    });
  }
  return formulations;
}

export async function getUnits(query: FormulationUnitQuery): Promise<FormulationUnit[]> {
  const result = await call('Formulating', 'GetUnits', [query.formulationText]);
  const reader = createReader(result);
  const units: FormulationUnit[] = [];
  while (reader.canRead()) {
    units.push({
      id: reader.readNumber(),
      code: reader.readString(),
      title: reader.readString(),
      createdDate: reader.readDate(),
    });
  }
  return units;
}

export async function getQuestions(query: FormulationQuestionQuery): Promise<FormulationQuestion[]> {
  const result = await call('Formulating', 'GetQuestions', [query.unitId, query.formulationText]);
  const reader = createReader(result);
  const questions: FormulationQuestion[] = [];
  while (reader.canRead()) {
    questions.push({
      idPart: reader.readNumber(),
      type: reader.readNumber(),
      text: reader.readString(),
      par1: reader.readNumber(),
      par2: reader.readNumber(),
      par3: reader.readNumber(),
      alias: reader.readString(),
      alts: readAlts(reader),
    });
  }
  return questions;
}

export async function createMultiUnit(data: {
  unitId: number;
  idPart: number;
  title: string;
  code: string;
  slaveUnitIds: number[];
}): Promise<{ masterUnitId: number; linkCount: number }> {
  const result = await call('Formulating', 'CreateMultiUnit', [
    data.unitId,
    data.idPart,
    data.title,
    data.code,
    ...data.slaveUnitIds,
  ]);
  const reader = createReader(result);
  const masterUnitId = reader.readNumber();
  const linkCount = reader.readNumber();
  return { masterUnitId, linkCount };
}

// private

function readAlts(reader: Reader): string[] {
  const count = reader.readNumber();
  const alts: string[] = [];
  for (let i = 0; i < count; i += 1) {
    alts.push(reader.readString());
  }
  return alts;
}
