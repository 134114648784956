import $ from 'jquery';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { RefObject } from 'react';

import { cn } from '../lib/utils.lib';

export type ButtonType =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark'
  | 'link'
  | 'outline-secondary';

interface P {
  type: ButtonType;
  text?: string;
  icon?: IconProp;
  size?: 'lg' | 'sm';
  className?: string;
  disabled?: boolean;
  dataDismiss?: string;
  dataToggle?: string;
  onClick?: () => void;
}

export class Button extends React.Component<P> {
  domNode?: RefObject<HTMLButtonElement>;

  constructor(props: P) {
    super(props);
    this.domNode = React.createRef();
  }

  render() {
    const { type, size, text, icon, className, disabled, dataDismiss, dataToggle } = this.props;
    return (
      <button
        ref={this.domNode}
        className={cn(className, `btn btn-${type}`, size && `btn-${size}`)}
        type="button"
        title={icon ? text : undefined}
        disabled={disabled}
        data-dismiss={dataDismiss}
        data-toggle={dataToggle}
        data-placement="top"
        data-trigger="hover"
        onClick={() => this.onClick()}
      >
        {icon ? undefined : text}
        {this.renderIcon()}
      </button>
    );
  }

  componentDidMount() {
    ($(this.domNode!.current!) as any).tooltip();
  }

  // event handlers

  onClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  // render helpers

  renderIcon() {
    const { icon } = this.props;
    if (!icon) {
      return null;
    }
    return <FontAwesomeIcon icon={icon} />;
  }
}
