import React from 'react';

import { formatQuestionType } from '../../services/fmt.service';
import { showModal } from '../../services/modal.service';
import { FormulationQuestion, FormulationUnit } from '../../services/sdk.service';
import { Button } from '../Button';
import { ModalHeader } from '../ModalHeader';
import { MMultiUnit } from './MMultiUnit';

interface P {
  unit: FormulationUnit;
  questions: FormulationQuestion[];
  units: FormulationUnit[];
  close: (value?: string) => void;
}

export class MQuestions extends React.Component<P> {
  render() {
    return (
      <div className="MQuestions modal-dialog modal-lg modal-dialog-scrollable">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  async onCreateMultiUnit() {
    const { unit, questions, units } = this.props;
    await showModal(MMultiUnit, { unit, question: questions[0], units });
  }

  // render helpers

  renderHeader() {
    const { close, unit } = this.props;
    return <ModalHeader title={unit.code} close={close} />;
  }

  renderBody() {
    const { questions } = this.props;
    return (
      <div className="modal-body">
        {questions.map((q, index) => this.renderQuestion(q, index === questions.length - 1))}
      </div>
    );
  }

  renderQuestion(q: FormulationQuestion, isLast: boolean) {
    return (
      <React.Fragment key={q.idPart}>
        <dl className="row">
          <dt className="col-3">Тип</dt>
          <dd className="col-9">{formatQuestionType(q.type)}</dd>
          <dt className="col-3">Формулировка</dt>
          <dd className="col-9">{q.text}</dd>
          {this.renderAlts(q)}
        </dl>
        {!isLast && <hr />}
      </React.Fragment>
    );
  }

  renderAlts(q: FormulationQuestion) {
    if (q.alts.length === 0) {
      return null;
    }
    return (
      <React.Fragment>
        <dt className="col-3">Варианты ответов</dt>
        <dd className="col-9">
          <ul>
            {q.alts.map((alt, index) => (
              <li key={index}>{alt}</li>
            ))}
          </ul>
        </dd>
      </React.Fragment>
    );
  }

  renderFooter() {
    const { close } = this.props;
    return (
      <div className="modal-footer">
        <div className="mr-auto">
          <Button type="info" icon="magic" text="Создать мультиопрос" onClick={() => this.onCreateMultiUnit()} />
        </div>
        <Button type="secondary" text="Закрыть" onClick={() => close()} />
      </div>
    );
  }
}
