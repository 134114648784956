import validator from 'validator';

import { formatDate } from './fmt.service';

export interface FormRawValues {
  [key: string]: any;
}

export interface FormValues {
  [key: string]: string;
}

export interface FormErrors {
  [key: string]: string | undefined;
}

export interface Form {
  getValues(): FormValues;
  getErrors(): FormErrors;
  getValue(name: string): string;
  getNumericValue(name: string): number | undefined;
  getMoneyValue(name: string): number | undefined;
  getAutocompleteValue(name: string): number | undefined;
  setValue(name: string, value: any): Form;
  hasError(name?: string): boolean;
  getError(name: string): string | undefined;
  setError(name: string, error: string): Form;
  resetError(name: string): Form;
  resetErrors(): Form;
  trimValues(): Form;
  validateRequired(name: string): Form;
  validateRegexp(name: string, regexp: RegExp, error?: string): Form;
  validateInteger(name: string, min?: number, max?: number): Form;
  validateFloat(name: string, min?: number, max?: number): Form;
  validateDate(name: string, min?: string, max?: string): Form;
  validateMoney(name: string, withKop?: boolean, min?: number, max?: number): Form;
  validateAutocomplete(name: string): Form;
  validateEmail(name: string): Form;
  validateUrl(name: string): Form;
  validateDomain(name: string): Form;
}

export function createForm(rawValues: FormRawValues = {}, errors: FormErrors = {}) {
  const values = prepareValues(rawValues);
  const form: Form = {
    getValues() {
      return values;
    },
    getErrors() {
      return errors;
    },
    getValue(name) {
      return values[name] || '';
    },
    getNumericValue(name) {
      const value = values[name];
      if (!value) {
        return undefined;
      }
      const cleanValue = value.replace(/\s/g, '').replace(',', '.');
      return Number(cleanValue);
    },
    getMoneyValue(name) {
      const numValue = form.getNumericValue(name);
      if (numValue === undefined) {
        return undefined;
      }
      return Math.round(numValue * 100);
    },
    getAutocompleteValue(name) {
      const value = values[name];
      if (!value) {
        return undefined;
      }
      const match = value.match(/^#(\d+)+#$/);
      if (!match) {
        return undefined;
      }
      return Number(match[1]);
    },
    setValue(name, value) {
      const newValues = { ...values, [name]: prepareValue(value) };
      return createForm(newValues, errors);
    },
    hasError(name) {
      if (name) {
        return errors[name] !== undefined;
      }
      for (const k of Object.keys(errors)) {
        if (errors[k] !== undefined) {
          return true;
        }
      }
      return false;
    },
    getError(name) {
      return errors[name];
    },
    setError(name, error) {
      const newErrors = { ...errors, [name]: error };
      return createForm(values, newErrors);
    },
    resetError(name) {
      const newErrors = { ...errors, [name]: undefined };
      return createForm(values, newErrors);
    },
    resetErrors() {
      return createForm(values, {});
    },
    trimValues() {
      const keys = Object.keys(values);
      const newValues: FormValues = {};
      for (const key of keys) {
        newValues[key] = values[key].trim();
      }
      return createForm(newValues, errors);
    },
    validateRequired(name) {
      if (values[name]) {
        return form;
      }
      const newErrors = { ...errors, [name]: 'Обязательное поле' };
      return createForm(values, newErrors);
    },
    validateRegexp(name, regexp, error = 'Неверный формат значения') {
      const value = values[name];
      if (!value) {
        return form;
      }
      if (regexp.test(value)) {
        return form;
      }
      const newErrors = { ...errors, [name]: error };
      return createForm(values, newErrors);
    },
    validateInteger(name, min, max) {
      const value = values[name];
      if (!value) {
        return form;
      }
      const cleanValue = value.replace(/\s/g, '');
      const regexp = /^\d+$/;
      if (!regexp.test(cleanValue)) {
        const newErrors = { ...errors, [name]: 'Неверный формат значения' };
        return createForm(values, newErrors);
      }
      const numValue = Number(cleanValue);
      if (min !== undefined && numValue < min) {
        return form.setError(name, `Значение должно быть не менее ${min}`);
      }
      if (max !== undefined && numValue > max) {
        return form.setError(name, `Значение должно быть не более ${max}`);
      }
      return form;
    },
    validateFloat(name, min, max) {
      const value = values[name];
      if (!value) {
        return form;
      }
      const cleanValue = value.replace(/\s/g, '').replace(',', '.');
      const regexp = /^\d+(\.\d+)?$/;
      if (!regexp.test(cleanValue)) {
        const newErrors = { ...errors, [name]: 'Неверный формат значения' };
        return createForm(values, newErrors);
      }
      const numValue = Number(cleanValue);
      if (min !== undefined && numValue < min) {
        return form.setError(name, `Значение должно быть не менее ${min}`);
      }
      if (max !== undefined && numValue > max) {
        return form.setError(name, `Значение должно быть не более ${max}`);
      }
      return form;
    },
    validateMoney(name, withKop = false, min = 0, max) {
      const value = values[name];
      if (!value) {
        return form;
      }
      if (withKop) {
        return form.validateFloat(name, min, max);
      }
      return form.validateInteger(name, min, max);
    },
    validateDate(name, min = '1900-01-01', max = '2999-01-01') {
      const value = values[name];
      if (!value) {
        return form;
      }
      if (value < min) {
        return form.setError(name, `Дата должна быть не ранее ${formatDate(min)}`);
      }
      if (value > max) {
        return form.setError(name, `Дата должна быть не позже ${formatDate(max)}`);
      }
      return form;
    },
    validateAutocomplete(name: string) {
      return form.validateRegexp(name, /^#\d+#$/, 'Выберите один из предлагаемых элементов');
    },
    validateEmail(name) {
      const value = values[name];
      if (!value) {
        return form;
      }
      if (!validator.isEmail(value)) {
        return form.setError(name, 'Неверный формат значения');
      }
      return form;
    },
    validateUrl(name) {
      const value = values[name];
      if (!value) {
        return form;
      }
      const isValid = validator.isURL(value, {
        protocols: ['http', 'https'],
        require_protocol: true,
        require_host: true,
      });
      if (!isValid) {
        return form.setError(name, 'Неверный формат значения');
      }
      return form;
    },
    validateDomain(name) {
      const value = values[name];
      if (!value) {
        return form;
      }
      const isValid = validator.isFQDN(value);
      if (!isValid) {
        return form.setError(name, 'Неверный формат значения');
      }
      return form;
    },
  };
  return form;
}

export function initAutocomplete(id: number | null | undefined) {
  return id == null ? '' : `#${id}#`;
}

export function getBooleanOptions() {
  return [
    { value: 'false', title: 'No' },
    { value: 'true', title: 'Yes' },
  ];
}

// private

function prepareValues(rawValues: FormRawValues) {
  const values: FormValues = {};
  const keys = Object.keys(rawValues);
  for (const key of keys) {
    values[key] = prepareValue(rawValues[key]);
  }
  return values;
}

function prepareValue(rawValue: any) {
  if (rawValue === null || rawValue === undefined) {
    return '';
  }
  return String(rawValue);
}
