/* eslint-disable jsx-a11y/anchor-is-valid, no-script-url */

import React from 'react';
import { getPagerButtons } from '../lib/pager.lib';
import { cn } from '../lib/utils.lib';

const DEFAULT_MAX_BUTTONS = 10;

interface P {
  total: number;
  pageSize: number;
  pageNumber: number;
  className?: string;
  maxButtons?: number;
  onSelect?: (page: number) => void;
}

export class Pager extends React.Component<P> {
  render() {
    const { className } = this.props;
    return (
      <nav className={className}>
        <ul className="pagination">{this.renderPages()}</ul>
      </nav>
    );
  }

  // event handlers

  onPageClick(page: number) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(page);
    }
  }

  // render helpers

  renderPages() {
    const { total, pageSize, pageNumber, maxButtons } = this.props;
    const pageCount = Math.floor((total - 1) / pageSize) + 1;
    const buttons = getPagerButtons(pageNumber, pageCount, maxButtons || DEFAULT_MAX_BUTTONS);
    return buttons.map((p) => this.renderPage(p, p === pageNumber));
  }

  renderPage(page: number, isCurrent: boolean) {
    const className = cn('page-item', isCurrent && 'active', page < 0 && 'disabled');
    return (
      <li className={className} key={page}>
        <a className="page-link" href="javascript:void(0)" onClick={() => this.onPageClick(page)}>
          {page < 0 ? '...' : page}
        </a>
      </li>
    );
  }
}
