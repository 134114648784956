import { createBrowserHistory } from 'history';

import { scrollToTop } from './ui.service';

const history = createBrowserHistory();

export function getHistory() {
  return history;
}

export function goTo(path: string) {
  history.push(path);
  scrollToTop();
}

export function isCurrentPath(path: string) {
  return history.location.pathname.startsWith(path);
}

export function getUrlParam(name: string, defaultValue = '') {
  const usp = new URLSearchParams(history.location.search);
  return usp.get(name) || defaultValue;
}

export function setUrlParam(name: string, value: string, defaultValue = '') {
  const usp = new URLSearchParams(history.location.search);
  if (value === defaultValue) {
    usp.delete(name);
  } else {
    usp.set(name, String(value));
  }
  history.replace(history.location.pathname + '?' + usp.toString());
}

export function parseUrlParams() {
  const usp = new URLSearchParams(history.location.search);
  const parsedParams: { [key: string]: string } = {};
  usp.forEach((value, key) => (parsedParams[key] = value));
  return parsedParams;
}

export function hasUrlParams() {
  const params = parseUrlParams();
  return Object.keys(params).length !== 0;
}

export function openInNewTab(url: string) {
  const win = window.open(url, '_blank');
  if (win) {
    win.focus();
  }
}
