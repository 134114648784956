type Updater = () => void;

let updater: Updater | undefined;

export function registerUiUpdater(f: Updater) {
  updater = f;
}

export function updateUi() {
  if (updater) {
    updater();
  }
}

export function scrollToTop(smooth = false) {
  window.scrollTo({ left: 0, top: 0, behavior: smooth ? 'smooth' : 'auto' });
}
