import React from 'react';

import { config } from '../../config';
import { pluralize } from '../../lib/utils.lib';
import { handleError } from '../../services/error.service';
import { createForm, Form } from '../../services/form.service';
import { createMultiUnit, FormulationQuestion, FormulationUnit } from '../../services/sdk.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';
import { Option } from '../Select';

interface P {
  close: () => void;
  unit: FormulationUnit;
  question: FormulationQuestion;
  units: FormulationUnit[];
}

interface S {
  form: Form;
  isLoading: boolean;
}

export class MMultiUnit extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isLoading: false,
      form: this.initForm(),
    };
  }

  render() {
    return (
      <div className="MMultiUnit modal-dialog modal-lg modal-dialog-scrollable">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form: Form) {
    this.setState({ form });
  }

  async onSave() {
    const { close } = this.props;
    let { form } = this.state;
    form = form.trimValues();
    form = form.validateRequired('title');
    form = form.validateRequired('code');
    if (form.hasError()) {
      this.setState({ form });
      alert(config.invalidFormMessage);
      return;
    }
    const unitIds = this.getIds(form, 'units');
    if (unitIds.length === 0) {
      alert('Выберите хотя бы один опрос');
      return;
    }
    this.setState({ isLoading: true });
    try {
      const pd = this.getPostData(form);
      const { linkCount } = await createMultiUnit(pd);
      alert(this.getResultMessage({ unitIds, linkCount }));
      close();
    } catch (err) {
      this.setState({ isLoading: false });
      handleError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close } = this.props;
    return <ModalHeader title="Новый мультиопрос" close={close} />;
  }

  renderBody() {
    const { units } = this.props;
    const { form } = this.state;
    const unitIds = this.getIds(form, 'units');
    return (
      <div className="modal-body">
        <div className="row">
          <div className="col-5">
            <FormGroup
              type="text"
              name="title"
              label="Название мультиопроса"
              required
              form={form}
              onChange={(x) => this.onFormChange(x)}
            />
            <FormGroup
              type="text"
              name="code"
              label="Код мультиопроса"
              required
              form={form}
              onChange={(x) => this.onFormChange(x)}
            />
            <div className="row">
              <div className="col">
                <Button
                  type="light"
                  text="Выбрать всё"
                  size="sm"
                  className="w-100"
                  disabled={unitIds.length === units.length}
                  onClick={() => this.setState({ form: form.setValue('units', this.getUnitsFormValue()) })}
                />
              </div>
              <div className="col">
                <Button
                  type="light"
                  text="Убрать всё"
                  size="sm"
                  className="w-100"
                  disabled={unitIds.length === 0}
                  onClick={() => this.setState({ form: form.setValue('units', '') })}
                />
              </div>
            </div>
          </div>
          <FormGroup
            className="col-7"
            type="checks"
            name="units"
            label={`Опросы (${unitIds.length})`}
            required
            form={form}
            options={this.getUnitsOptions()}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" disabled={isLoading} onClick={() => close()} />
        <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  initForm() {
    return createForm({
      title: '',
      code: '',
      units: this.getUnitsFormValue(),
    });
  }

  getUnitsFormValue() {
    const { units } = this.props;
    return units.map((u) => u.id).join('\n');
  }

  getUnitsOptions(): Option[] {
    const { units } = this.props;
    return units.map((u) => ({ value: String(u.id), title: u.code }));
  }

  getPostData(form: Form) {
    const { unit, question } = this.props;
    return {
      unitId: unit.id,
      idPart: question.idPart,
      title: form.getValue('title'),
      code: form.getValue('code'),
      slaveUnitIds: this.getIds(form, 'units'),
    };
  }

  getIds(form: Form, name: string) {
    const value = form.getValue(name);
    if (!value) {
      return [];
    }
    return value.split('\n').map(Number);
  }

  getResultMessage({ unitIds, linkCount }: { unitIds: number[]; linkCount: number }): string {
    if (unitIds.length === linkCount) {
      return 'Мультиопрос создан, теперь его можно открыть в G-System';
    }
    const xFromY = `${linkCount} из ${pluralize(unitIds.length, 'опроса', 'опросов', 'опросов')}`;
    return `Мультиопрос создан, теперь его можно открыть в G-System. Вопрос был привяан в ${xFromY}. Вопрос может быть привязан, когда совпадают варианты ответов и достаточно прав доступа.`;
  }
}
